import MainRouter from '@routers/MainRouter';

import SnackbarError from './components/SnackbarError/SnackbarError';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CarbonModelType } from './services/authService/authServiceTypes';
import { AppStateT } from './store/store';
import { ROLE_ID } from './constants/roleIds';

const App = (): JSX.Element => {
    const isFarmer = useSelector((state: AppStateT) => state.auth.user?.role_id === ROLE_ID.FARMER);
    const isV3 = useSelector((state: AppStateT) => state.auth.farmer_infos?.model_type !== CarbonModelType.CFT);
    useEffect(() => {
        if (isV3 && isFarmer) window.location.replace(process.env.REACT_APP_V3_BASE_URL);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <SnackbarError />
            <MainRouter />
        </>
    );
};

export default App;
